import React from "react"
import { Helmet } from "react-helmet"

// Images
import outside from "../assets/images/outside.svg"

const About = () => {
  return (
    <>
      <Helmet>
        <title>About</title>
      </Helmet>
      <article className="[ wrapper ] [ article ]">
        <img
          className="hero"
          src={outside}
          alt="Illustration of two women talking in their balcony"
        />
        <h1>Our mission</h1>
        <p>Form learning pods by helping families connect</p>
        <h2>About us</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam tempora
          ut nulla aperiam rem explicabo dolorem a error! Reiciendis quisquam
          provident dignissimos impedit quia perferendis ex officia?
          Repellendus, aliquam enim.
        </p>
        <h3>Day 1</h3>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae
          suscipit accusantium voluptas. Dolorem quasi nisi, accusamus iure cum
          incidunt aperiam vero. Hic excepturi necessitatibus, cumque veniam
          repudiandae ex minus ratione.
        </p>
        <h3>Looking forward</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora quos
          dolorem ducimus optio voluptates, omnis dolor dolorum impedit
          repellendus nulla ab, quis aperiam nesciunt praesentium minus quisquam
          expedita atque deserunt.
        </p>
      </article>
    </>
  )
}

export default About
